import React from "react"
import * as styles from "./BlogPost.module.scss"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ResponsiveImage from "../responsiveImage"
const { BLOCKS, MARKS, INLINES } = require("@contentful/rich-text-types")

const options = {
  /*
   * Defines custom html string for each node type like heading, embedded entries etc..
   */
  renderNode: {
    // Example
    [INLINES.ASSET_HYPERLINK]: node => {
      return (
        <img
          class="custom-asset"
          src={
            node.data.target.fields &&
            node.data.target.fields.file &&
            node.data.target.fields.file["en-US"].url
          }
        />
      )
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      return (
        <div class="custom-entry">
          {node.data.target.fields &&
            node.data.target.fields.name &&
            node.data.target.fields.name["en-US"]}
        </div>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { title, file } = node.data.target

      return title ? (
        <div class="image-container">
          <img src={file.url} />
          <div class="image-caption">{title}</div>
        </div>
      ) : (
        <div class="image-container">
          <img src={file.url} />
        </div>
      )
    },
  },
  renderMark: {
    [MARKS.BOLD]: text => <span class="mark-bold">{text}</span>,
  },
}

function BlogPost({ blogPost }) {
  return (
    <div className={styles.container}>
      <div className={styles.date}>{blogPost.publishDate}</div>
      {blogPost.title ? (
        <div className={styles.title}>{blogPost.title}</div>
      ) : null}
      <div className={styles.featureImage}>
        <ResponsiveImage
          images={blogPost.featureImage.images}
          respondToWidth={true}
        />
      </div>
      <div className="blog-post-container">
        {blogPost.contents && renderRichText(blogPost.contents, options)}
        {blogPost.html && (
          <div dangerouslySetInnerHTML={{ __html: blogPost.html.html }}></div>
        )}
      </div>
    </div>
  )
}

export default BlogPost
