// extracted by mini-css-extract-plugin
export var hideForMobile = "BlogPost-module--hide-for-mobile--YDvQr";
export var hideForTablet = "BlogPost-module--hide-for-tablet--ib6oZ";
export var hideForLaptop = "BlogPost-module--hide-for-laptop--OyEFp";
export var hideForDesktop = "BlogPost-module--hide-for-desktop--iWEeC";
export var hideForExtraLarge = "BlogPost-module--hide-for-extra-large--iuvL2";
export var showForMobileOnly = "BlogPost-module--show-for-mobile-only--fGRBC";
export var showForTabletOnly = "BlogPost-module--show-for-tablet-only--x1EL6";
export var showForLaptopOnly = "BlogPost-module--show-for-laptop-only--O7o2E";
export var showForDesktopOnly = "BlogPost-module--show-for-desktop-only--0xye9";
export var showForExtraLargeOnly = "BlogPost-module--show-for-extra-large-only--tMp3a";
export var fullPageContainer = "BlogPost-module--full-page-container--Obbkm";
export var container = "BlogPost-module--container--r1StN";
export var date = "BlogPost-module--date--UvEKG";
export var title = "BlogPost-module--title--NcwO9";
export var featureImage = "BlogPost-module--featureImage--mMNQA";