import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import BlogPost from "../components/BlogPost"
const BlogList = loadable(() => import("../components/BlogList"))

const BlogPostPage = ({ data, pageContext }) => {
  const {
    contentfulBlogPost: blogPost,
    relatedBlogPosts: { edges: relatedBlogPosts },
  } = data
  const blogPosts = relatedBlogPosts
    .map(node => node.node)
    .sort((a, b) => {
      const aValue = a.tags.reduce((acc, current) => {
        if (pageContext.tags.indexOf(current) > -1) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      const bValue = b.tags.reduce((acc, current) => {
        if (pageContext.tags.indexOf(current) > -1) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      return bValue - aValue
    })
    .slice(0, 3)

  return (
    <FadeIn>
      <SEO
        title={`The Juice | ${blogPost.title}`}
        featuredImage={blogPost.featureImage.images[0].src}
        description={blogPost.metaDescription ? blogPost.metaDescription.metaDescription : blogPost.title}
      />
      <BlogPost blogPost={blogPost} />
      <BlogList blogPosts={blogPosts} />
    </FadeIn>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      publishDate(formatString: "MM.DD.YYYY")
      metaDescription {
        metaDescription
      }
      featureImage {
        images {
          title
          gatsbyImageData(quality: 70)
          file {
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
      }
      contents {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            file {
              url
            }
          }
        }
      }
      html {
        html
      }
    }
    relatedBlogPosts: allContentfulBlogPost(
      filter: { publishDate: { ne: null }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          id
          slug
          title
          tags
          publishDate(formatString: "MMMM DD")
          featureImage {
            images {
              title
              gatsbyImageData(quality: 70)
              file {
                details {
                  size
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPostPage
